import "./App.css";
import ScrollToTop from "./components/scrolltotop/ScrollToTop";

import { Navbar, Header, Features, Faq, Subscribe, Footer } from "./components";

function App() {
  return (
    <>
      <header className="header-bg">
        <Navbar />
        <Header />
      </header>
      <ScrollToTop />
      <Features data-aos="fade-up" />
      <Faq />
      <Subscribe />
      <Footer />
    </>
  );
}

export default App;
