import { TiTick } from "react-icons/ti";

export const FeatureList = [
  {
    id: 1,
    icon: <TiTick color="#0a1930" size={22} />,
    heading: "INITIAL APPLICATION",
    text: "We charge a career counseling fee of only 350 USD. Our highly experienced staff will gather your information, assess your situation and provide guidance to you on the best pathway forward..",
  },
  {
    id: 2,
    icon: <TiTick color="#0a1930" size={22} />,
    heading: "VISA READY",
    text: "Before you submit yourself to an employer for work you must be Visa Ready. That means you have the correct level of English, your skills are recognized in Australia and you have all your paperwork in order. We do all that with you for only 1,500 USD. Note: you will have to pay outlays, which could include English test and skill recognition fees. We will advise you of the budget, if any, before you pay to become visa ready. How long this takes is largely up to you.",
  },
  {
    id: 3,
    icon: <TiTick color="#0a1930" size={22} />,
    heading: "RECRUITMENT AND MOVE TO AUSTRALIA",
    text: "If you are Visa Ready already (you have English, Trade Recognition etc.) you can start here! There is absolutely no charge to you for your recruitment by an employer (the employer pays for all of it for you)! We will assist in finding an employer for you, help you with interview preparation, help you meet the employers requirements, assist the employer migration agent to help you, assist you to get to Australia with organising flights and moving, and, when you get to Australia, support you on arrival to find accommodation, register for Tax, get Superannuation set up, get a Bank Account setup and a Mobile Phone number. So you are not alone and ready to start your new life in Australia working for your employer!",
  },
];
