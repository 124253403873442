export const questions = [
  {
    id: 1,
    title: "How much will it cost?",
    answer:
      "The cost varies a lot depending upon your job, your country and your prospective employer. We charge only 350 USD to do the initial career counselling and after that we will give you a much better idea of your specific budget. The next step, after our counselling, is to become Visa Ready. Our fee for Visa Ready is only 1,500 USD. Other than those known outlays, there are a number of other fees that you MIGHT need to pay. Again, we will give you a budget as part our counselling. Some of the things you MIGHT need to pay for include Trade Recognition, English Test, medical, visa and flight to Australia. You will need to budget for your accommodation and living when you first arrive also. You will normally be paid weekly or fortnightly (so it is not long before you will get income). The employer has a lot of things they must pay for, in some cases, the employer may help you also but this is not mandatory. If you want a very general idea you must consider a maximum budget of about 15,000 AUD but don't put that together now. We will guide you!",
    note: "The ultimate question is: will you invest up to 15,000 AUD once off now to make more than 212,000 AUD over the next 4 Years?",
  },
  {
    id: 2,
    title: "What about pastoral care?",
    answer:
      "Speedy Global takes the welfare of our candidates very seriously. We provide ongoing assistance to you and ensure you are happy with your work, accommodation, etc. Furthermore, we only engage with quality employers who also take the welfare of their staff very seriously. So you will be respected, well supported and happy. We provide an ongoing support service that you can use at anytime while on your first visa. We even make sure you get safely from the airport, when you land, and that you have all your requirements in place quickly, after your land, to start work quickly (Tax File Number, Superannuation Account, Bank Account, Phone Number etc.)",
    note: "We are always available if you need help while on your first visa.",
  },
  {
    id: 3,
    title: "What about English?",
    answer:
      "If you are not exempt from English requirements, or if you don't already have a qualifying English Score, then you will need to do a test. If you are worried - don't.  We can help you with a special course to get your English up to the level you need. But don't do anything now. We will advise you as part of our counselling and we will support you as part of our Visa Ready program. Don't 'assume' anything; let our professional consultants guide you. For example: a Chef will usually need an IELTS of 5.0 in all bands (or equivalent) to come to Australia. However, there are exceptions and in some cases it is as low a 4.0 in some bands. So apply and let us guide you: it is the fastest way for you to get to Australia.",
    note: "A formal English test, if you are not exempt, will be required but let us guide you.",
  },
  {
    id: 4,
    title: "Do I need Health Cover?",
    answer:
      "Yes you will, but don't worry. As part of our service we will help you and make sure you have the correct cover in place for the visa and job you have.",
    note: "We will make sure you have the right cover.",
  },
  {
    id: 5,
    title: "How do I apply?",
    answer:
      "Please fill in our 'subscribe' form on this page or contact your nearest Speedy Global Office or partner: https://Speedy.Global/contact",
    note: "Subscribing on this page is the fastest way to start the process.",
  },
  {
    id: 6,
    title: "What jobs can I apply for?",
    answer:
      "The list is very long of jobs that you could be sponsored to do in Australia. It is not one list either: there is a National list, State lists, Industry lists and special region lists. We specialise in the Hospitality Industry but we can help people from any industry to get sponsored work in Australia. It costs nothing at all for you to submit the initial review form and for our professional team read it. If we can not help you we will tell you what you need to do to qualify.",
    note: "Please contact Speedy Global, tell us your situation and we will give you advice.",
  },
  {
    id: 7,
    title: "How long does the process take?",
    answer:
      "Both the Employers and Speedy Global process you very quickly. The unknown is how long the Australian Immigration Department will take to process your visa. We have no control over this at all.",
    note: "See https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-processing-times/global-processing-times#",
  },
  {
    id: 8,
    title: "Will a MARA Migration Agent do my visa?",
    answer:
      "YES - the employer will normally engage a professional Migration Agent to do the nomination and proess everything with the Australian Government. You, the employee, may have to pay all or part of the migration agent fee, which is set by them. We will let you know the conditions before you accept so there is no surprises. We will assist you through the whole process so you don't have anything to worry about.",
  },
  {
    id: 9,
    title: "Will the employer pay for anything?",
    answer:
      "YES - they actually pay a lot of money for every person they nominate to sponsor. The employer cannot recover what they must spend from you! There are some things, like moving costs, dependent costs and other costs that an employer is not required to pay for. Some will assist you with those costs as well. But you should not be expected that the employer will pay for things they don't have to pay for by law. It is fair.",
  },
  {
    id: 10,
    title: "Do I pay advertising to employers, for interviews or for the job?",
    answer:
      "NO - Never. The employer will pay to advertise to you. There is absolutely no fee, what so ever, for an interview and you NEVER have to pay anything to the employer, or us, for the job itself.",
  },
  {
    id: 11,
    title: "Do I need to pay Tax?",
    answer:
      "All people living in Australia and earning income in Australia are required to pay Australian income tax. It will normally be deducted from your wages by your employer and paid to the government for you. The amount is a sliding scale (the more you get the more you pay) and there is a tax free amount. It is the same for everyone and it is fair! It is the reason we have such a good country to live in.",
  },
  {
    id: 12,
    title: "What is Superannuation?",
    answer:
      "Superannuation is money set aside over your lifetime to provide for your retirement. It is paid by the employer to you on top of your income. It is not part of your base income. If you leave Australia permanently before you retire you can access it then. If you remain till you retire it will be paid to you then. So it is basically a compulsory savings account that the employer pays into for you. You can also add to your own super, if you like.",
  },
  {
    id: 13,
    title: "What are my job conditions?",
    answer:
      "You will have exactly the same conditions as all the other workers at your workplace. These conditions must also meet the minimum Australian standards. There is no requirement for employers to provide uniforms, however, some do. If they do then you will have yours provided. If they don't you will be expected to pay for them - the same as all the other employees in that business.",
  },
  {
    id: 14,
    title: "What about Accommodation?",
    answer:
      "When you come to Australia think of yourself as an Australian. It is not up to employers to find us accommodation. Some do, in special circumstances, and  if they do provide accommodation for other Australians then they will for you, Normally however, it is up to you to sort out. Remember Speedy Global will assist you to make sure you will be fine.",
  },
  {
    id: 15,
    title: "If I cannot go quickly should I say first?",
    answer:
      "YES! Always tell us everything before we put you up to an employer. Do not say you want to go quickly if you cannot. If you need to consider your situation do it before we get you a job.",
  },
];
