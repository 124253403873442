import React, { useEffect } from "react";
import AOS from "aos";
import { BsMouse } from "react-icons/bs";

import stepHeaderImg from "../../assets/TSS-General.png";

import "./Header.css";
import "../UI/Button/Button.css";
import "aos/dist/aos.css";

const Header = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <section id="header">
      <div className="container">
        <div id="header-main" className="u-text u-text-light">
          <div id="header-left" data-aos="fade-right">
            <h1>
              Move to Australia
              <div id="header-subtitle">
                <span>W</span>ork <span>L</span>ive and <span>S</span>tay
              </div>
            </h1>
            <h2>&gt; Live in one of the best countries in the world</h2>
            <h2>&gt; Get very well paid for the work you do</h2>
            <h2>&gt; Pathways to Permanent Residency</h2>
            <h2>&gt; Invest for your fantastic future</h2>
            <h2>&gt; Make more than AU$212,000 over 4 years</h2>
            <h2 id="headerTag">
              <i>Change your life for the better NOW!</i>
            </h2>

            <hr />
          </div>
          <div id="header-right" data-aos="fade-left">
            <img
              src={stepHeaderImg}
              alt="Study Training Employment Play - General Image"
              aria-hidden="true"
            />
          </div>
        </div>
        <div id="floating-icon">
          <a href="#features">
            <BsMouse color="#fff" size={25} id="mouse" />
          </a>
        </div>
      </div>
    </section>
  );
};

export default Header;
